
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChartData, ChartTooltipModelBody, ChartTooltipModel } from 'chart.js';
import moment from 'moment';

@Component({
    components: {},
})
export default class ScoreGraphTooltip extends Vue {
    @Prop({
        type: Object as () => ChartData,
    })
    private chartData!: ChartData;

    @Prop({ required: false, type: Object as () => ChartTooltipModel })
    tooltipModel!: ChartTooltipModel;

    get titleDate() {
        // @ts-ignore TODO: Fix console error
        const titleLines = this.tooltipModel.title || [];
        let date: string | null = null;
        titleLines.forEach(title => {
            date = moment(new Date(title)).format('DD-MMM-YYYY').toUpperCase();
        });
        return date;
    }

    getBody(bodyItem: ChartTooltipModelBody) {
        return bodyItem.lines;
    }

    get getTooltipData() {
        const arr: any = [];
        if (this.tooltipModel.body) {
            const bodyLines = this.tooltipModel.body.map(this.getBody);
            bodyLines.forEach((body: string[]) => {
                const lineText = body[0];
                const numbers = lineText.match(/\d+$/);
                let value = '';
                let title = '';
                if (lineText && numbers && numbers.length) {
                    [value] = numbers;
                    title = lineText.replace(value, '').replace(': ', '');
                }
                arr.push({ txt: value ? title : lineText, val: value });
            });
        }
        return arr;
    }
}
