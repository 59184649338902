
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import USER_LEVELS from '../../user/constants/user-levels.constant';

@Component
export default class HomeHotelTitle extends Vue {
    @inject(UserServiceS) protected userService!: UserService;
    @inject(HotelsServiceS) protected hotelsService!: HotelsService;

    get name(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        if (this.userService.viewAs === USER_LEVELS.CHAIN || this.userService.viewAs === USER_LEVELS.CLUSTER) {
            return this.userService.levelName;
        }

        return this.hotelsService.getHotelName(currentHotelId);
    }
}
