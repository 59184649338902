
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CiScore from '@/modules/common/components/ci-score.vue';
import ClusterScoresService, { ClusterScoresServiceS } from '../../modules/score/score.service';

@Component({
    components: {
        CiScore,
    },
})
export default class ClusterCiScore extends Vue {
    @inject(ClusterScoresServiceS) clusterScoresService!: ClusterScoresService;

    score() {
        if (!this.clusterScoresService.data.scoreStatisticsHistory) {
            return null;
        }

        return this.clusterScoresService.score;
    }

    scoreDif() {
        if (!this.clusterScoresService.data.scoreStatisticsHistory) {
            return null;
        }
        return this.clusterScoresService.scoreDiff;
    }

    get isLoading() {
        return this.clusterScoresService.isLoading;
    }
}
